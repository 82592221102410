<template>
  <div class="root">
    <div class="article">
      <el-form :model="article" class="mu-demo-form" label-position="left" label-width="100">
        <el-form-item label="标题">
          <el-input v-model="article.title"></el-input>
        </el-form-item>
        <el-form-item  label="图片">
          <input type="file" @change="getFile">
            <img :src="selectedImage" alt="选择图片" class="thumb-image">
        </el-form-item>
        <el-form-item label="内容" style="display: flex;">
          <post-editor @onChange="handelContentChange" />
        </el-form-item>
        <el-form-item label="标签">
          <el-checkbox-group v-model="article.tags">
            <el-checkbox v-for="item of tags" :key="item.id" :label="item.name" :value="item.id"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="articleSubmit()">提交</el-button>
    </div>
  </div>
</template>
<script>
import { config } from '@/assets/config'
import PostEditor from '../../../components/postEditor/postEditor.vue'
export default {
  name: 'AdminArticleAdd',
  components: {PostEditor},
  data () {
    return {
      prefix: config.prefix,
      tags: [],
      article: {
        title: '',
        imgurl: '',
        content: '',
        tags: []
      },
      selectedImage: ''
    }
  },
  methods: {
    getTags () {
      this.$axios.get('/api/tag')
        .then(res => {
          this.tags = res.data
        })
        .catch(err => console.log(err))
    },
    articleSubmit () {
      const currentPost = {...this.article, tags: this.tags.filter(({name}) => this.article.tags.includes(name)).map(({ id }) => id)}
      this.$axios.post('/api/article', currentPost)
        .then(() => this.$router.push({ path: '/admin/article' }))
        .catch(err => console.log(err))
    },
    getFile (e) {
      const file = e.target.files[0]
      // image validation
      let arr = file.type.split('/')
      let valid = arr[0] === 'image' && (arr[1] === 'png' || arr[1] === 'jpg' || arr[1] === 'jpeg')
      if (!valid) {
        alert('选择图片格式错误')
        return
      }
      this.article.imgurl = file
      // show selected image
      let reader = new FileReader()
      let that = this
      reader.onload = function () {
        that.selectedImage = this.result
      }
      reader.readAsDataURL(file)
    },
    handelContentChange (content) {
      this.article.content = content;
    }
  },
  mounted () {
    this.getTags()
  }
}
</script>
<style lang="stylus" scoped>
.root
  max-width 100%
  overflow hidden
.article
  padding-top 100px
  padding-left 20px
  .thumb-image
    max-width 100px
    max-height 100px
    object-fit cover
</style>
